<template>
  <div id="main-wrapper" class="homePage">
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->

    <!-- End Navigation -->
    <div class="clearfix"></div>
    <!-- ============================================================== -->
    <!-- Top header  -->
    <!-- ============================================================== -->

    <!-- ============================ Hero Banner  Start================================== -->
    <div
      class="image-cover hero-banner hero-inner-2 shadow rlt"
      :style="
        `
        background: url(${event.image}) no-repeat;
      `
      "
      data-overlay="7"
    >
      <div class="elix-img-box">
        <img src="assets/img/preet-o.png" class="img-fluid" alt="" />
      </div>
      <div class="container page-container">
        <div class="hero-caption small-wd mb-5">
          <h1 class="big-header-capt cl-2 mb-0">971Tutors.com</h1>
          <p>The easiest platform to find the right tutor for your needs!</p>
        </div>
        <b-container
          v-show="
            !gt_user || (gt_user.account && gt_user.account.role != 'tutor')
          "
        >
          <b-row class="search-wrapper">
            <b-col cols="12">
              <h4 class="search-title">Look for a tutor</h4>
            </b-col>
            <b-col
              sm="12"
              md="12"
              lg="3"
              xl="3"
              class="mt-3"
              style="z-index: 99"
            >
              <div class="text-center">
                <multiselect
                  selectLabel="Select"
                  deselectLabel="Remove"
                  v-model="co_selectedSubject"
                  class="multiselect-main-class"
                  style="margin: auto"
                  :options="co_getSubjects"
                  :close-on-select="true"
                  label="en"
                  placeholder="Choose a subject"
                  track-by="key"
                  open-direction="bottom"
                  :show-labels="false"
                >
                  <template slot="option" slot-scope="option">
                    <span
                      >{{ option.option.en }} in
                      {{ d_educationLevels[option.option.education] }}</span
                    >
                  </template>
                </multiselect>
              </div>
            </b-col>
            <b-col
              sm="12"
              md="12"
              lg="3"
              xl="3"
              class="mt-3"
              style="z-index: 98"
            >
              <div class="text-center">
                <multiselect
                  selectLabel="Select"
                  deselectLabel="Remove"
                  class="multiselect-main-class"
                  style="margin: auto; min-width: 100%"
                  v-model="co_selectedGrade"
                  :options="gt_getGrades"
                  :close-on-select="true"
                  :disabled="
                    !(co_selectedSubject && co_selectedSubject.education == 's')
                  "
                  :placeholder="
                    co_selectedSubject && co_selectedSubject.education == 's'
                      ? 'Choose a grade'
                      : 'No grades'
                  "
                  open-direction="bottom"
                  :show-labels="false"
                >
                </multiselect>
              </div>
            </b-col>
            <b-col
              sm="12"
              md="12"
              lg="3"
              xl="3"
              class="mt-3"
              style="z-index: 97"
            >
              <div class="text-center">
                <multiselect
                  selectLabel="Select"
                  deselectLabel="Remove"
                  class="multiselect-main-class"
                  style="margin: auto; min-width: 100%"
                  v-model="co_selectedCurriculum"
                  :options="gt_curriculumsSelectItem"
                  label="en"
                  track-by="key"
                  :close-on-select="true"
                  :disabled="
                    !(co_selectedGrade && co_selectedSubject.education == 's')
                  "
                  :placeholder="
                    co_selectedGrade && co_selectedSubject.education == 's'
                      ? 'Choose a curriculum'
                      : 'No curriculums'
                  "
                  open-direction="bottom"
                  :show-labels="false"
                >
                </multiselect>
              </div>
            </b-col>
            <b-col
              sm="12"
              md="12"
              lg="3"
              xl="3"
              class="mb-3 main-search-parent-button"
              style="z-index: 96"
            >
              <b-button
                class="search-button"
                :disabled="!m_isSearchValid()"
                variant="transparent"
                size="lg"
                :to="{
                  path: `/subject/${
                    co_selectedSubject ? this.co_selectedSubject.key : ''
                  }`,
                  query: {
                    grade: this.co_selectedGrade,
                    curriculum: this.co_selectedCurriculum,
                  },
                }"
              >
                <i
                  style="font-size: 2em; color: #82bf6f"
                  class="fa fa-search"
                ></i>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
        <!-- </b-container> -->
      </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->
    <!-- ============================ Video Section Start ================================== -->
    <section
      class="bg-cover newsletter inverse-theme"
      style="
        background: url(/img/971TutorsImages/categories/homepage.jpeg) no-repeat;
      "
      data-overlay="12"
    >
      <div class="ed_detail_head">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7 col-md-7" v-if="false">
              <div class="property_video">
                <div class="thumb">
                  <div>
                    <b-embed
                      type="iframe"
                      aspect="16by9"
                      :src="event.video"
                      allowfullscreen
                    ></b-embed>
                  </div>
                  <!-- <img
                    class="pro_img img-fluid w100"
                    src="https://via.placeholder.com/700x500"
                    alt="7.jpg"
                  />
                   <div class="overlay_icon">
                    <div class="bb-video-box">
                      <div class="bb-video-box-inner">
                        <div class="bb-video-box-innerup">
                          <a
                            href="https://www.youtube.com/watch?v=A8EI6JaFbv4"
                            data-toggle="modal"
                            data-target="#popup-video"
                            class="theme-cl"
                            ><i class="ti-control-play"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 text-center">
              <div class="ed_detail_wrap">
                <div class="ed_header_caption">
                  <h2 class="ed_title">{{ event.title }}</h2>
                </div>
                <div class="ed_header_short">
                  <p>
                    {{ event.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Video Section End ================================== -->
    <!-- ============================ Trips Facts Start ================================== -->
    <section class="p-0 trips-top" style="background:#052044;">
      <div class="container" style="padding-bottom:80px">
        <div class="trips-wrap">
          <div class="row m-0">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="trips">
                <div class="trips-icons">
                  <i class="ti-video-camera"></i>
                </div>
                <div class="trips-detail">
                  <h4>Private Sessions</h4>
                  <p>
                    Offline/Online private sessions in the UAE and around the
                    Middle East
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="trips">
                <div class="trips-icons">
                  <i class="ti-medall"></i>
                </div>
                <div class="trips-detail">
                  <h4>Expert instruction</h4>
                  <p>
                    Tutors can customize the lessons just for you or for your
                    child
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="trips none">
                <div class="trips-icons">
                  <i class="ti-infinite"></i>
                </div>
                <div class="trips-detail">
                  <h4>One-on-one attention</h4>
                  <p>Teaching methods according to individual learning style</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ============================ Trips Facts Start ================================== -->

    <!-- ============================ Featured Category Start ================================== -->

    <!-- ============================ Featured Category End ================================== -->

    <!-- ============================ Testimonial Start ================================== -->
    <!-- <section style="background: url(img/testimonial.png)">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-6 col-sm-12">
            <div class="sec-heading center">
              <p>What People Say?</p>
              <h2>
                <span class="theme-cl">Reviews</span> By Our Success & Top
                Students
              </h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <VueSlickCarousel :arrows="true" :dots="true">
              <div class="testimonial-detail">
                <div class="client-detail-box">
                  <div class="pic">
                    <img src="https://via.placeholder.com/500x500" alt="" />
                  </div>
                  <div class="client-detail">
                    <h3 class="testimonial-title">Adam Alloriam</h3>
                    <span class="post">Web Developer</span>
                  </div>
                </div>
                <p class="description">
                  " Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Autem commodi eligendi facilis itaque minus non odio, quaerat
                  ullam eligendi facilis itaque minus non odio, quaerat ullam
                  unde unde voluptatum Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. Autem commodi eligendi. "
                </p>
              </div>

              <div class="testimonial-detail">
                <div class="client-detail-box">
                  <div class="pic">
                    <img src="https://via.placeholder.com/500x500" alt="" />
                  </div>
                  <div class="client-detail">
                    <h3 class="testimonial-title">Illa Millia</h3>
                    <span class="post">Project Manager</span>
                  </div>
                </div>
                <p class="description">
                  " Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Autem commodi eligendi facilis itaque minus non odio, quaerat
                  ullam unde voluptatum eligendi facilis itaque minus non odio,
                  quaerat ullam unde Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. Autem commodi eligendi."
                </p>
              </div>

              <div class="testimonial-detail">
                <div class="client-detail-box">
                  <div class="pic">
                    <img src="https://via.placeholder.com/500x500" alt="" />
                  </div>
                  <div class="client-detail">
                    <h3 class="testimonial-title">Rout Millance</h3>
                    <span class="post">Web Designer</span>
                  </div>
                </div>
                <p class="description">
                  " Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Autem commodi eligendi facilis itaque minus non odio, quaerat
                  ullam unde voluptatum Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. Autem commodi eligendi."
                </p>
              </div>

              <div class="testimonial-detail">
                <div class="client-detail-box">
                  <div class="pic">
                    <img src="https://via.placeholder.com/500x500" alt="" />
                  </div>
                  <div class="client-detail">
                    <h3 class="testimonial-title">williamson</h3>
                    <span class="post">Web Developer</span>
                  </div>
                </div>
                <p class="description">
                  " Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Autem commodi eligendi facilis itaque minus non odio, quaerat
                  ullam unde voluptatum eligendi facilis itaque minus non odio,
                  quaerat ullam unde ? "
                </p>
              </div>
            </VueSlickCarousel>
         
          </div>
        </div>
      </div>
    </section> -->
    <!-- ============================ Testimonial End ================================== -->
    <section
      class="bg-cover newsletter inverse-theme"
      style="
        background: url(/img/971TutorsImages/categories/homepage.jpeg) no-repeat;
      "
      data-overlay="9"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-8 col-sm-12">
            <div class="text-center">
              <h2>How much we have helped?</h2>
              <p>One small step for students, one big leap in growth</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div class="container">
        <div class="trips-wrap">
          <div class="row m-0">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="trips">
                <div class="trips-icons">
                  <b-icon-camera-video></b-icon-camera-video>
                </div>
                <div class="trips-detail">
                  <h2 style="color: #82bf6f">{{ event.teachersNumber }}+</h2>
                  <p>Teachers found jobs locally as well as overseas</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="trips">
                <div class="trips-icons">
                  <b-icon-trophy></b-icon-trophy>
                </div>
                <div class="trips-detail">
                  <h2 style="color: #82bf6f">{{ event.studentsNumber }}+</h2>
                  <p>
                    Students succeeding in their courses at school and
                    university
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="trips none">
                <div class="trips-icons">
                  <b-icon-app-indicator></b-icon-app-indicator>
                </div>
                <div class="trips-detail">
                  <h2 style="color: #82bf6f">{{ event.familiesNumber }}+</h2>
                  <p>Families are happy with using such a simple platform</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// import c_vueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import c_multiselect from "vue-multiselect";
import { fb_eventsRef } from "@/lib-core/lib-firebase";
export default {
  name: "cmp-home",
  components: {
    // VueSlickCarousel: c_vueSlickCarousel,
    multiselect: c_multiselect,
  },
  data() {
    return {
      d_educationLevels: {
        a: "All",
        s: "School",
        u: "University",
        l: "Languages",
        o: "Others",
      },
      d_selectedSubject: "",
      d_selectedCurriculum: "",
      d_selectedGrade: "",
      d_searchText: "",
      d_searchData: [],
      event: {
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        video: "https://www.youtube.com/embed/Z3GfYFUQAE0",
        image: "/img/971TutorsImages/categories/homepage.jpeg",
        title: "Event",
        teachersNumber: "1000",
        studentsNumber: "5000",
        familiesNumber: "2000",
      },
    };
  },
  computed: {
    ...mapGetters("md_fire", [
      "gt_user",
      "gt_curriculumsSelectItem",
      "gt_getGrades",
      "gt_subjects",
    ]),
    ...mapGetters("md_auth", [
      "gt_isVerified",
      "gt_authUser",
      "gt_isFacebookLogin",
    ]),

    co_getSubjects() {
      if (!this.gt_subjects) return [];
      return Object.values(this.gt_subjects);
    },
    co_selectedSubject: {
      get() {
        return this.$store.getters["md_fire/gt_getHomepageSubject"];
      },
      set(p_subject) {
        this.$store.commit("md_fire/mt_setHomepageSubject", p_subject);
      },
    },
    co_selectedGrade: {
      get() {
        return this.$store.getters["md_fire/gt_getHomepageGrade"];
      },
      set(p_grade) {
        this.$store.commit("md_fire/mt_setHomepageGrade", p_grade);
      },
    },
    co_selectedCurriculum: {
      get() {
        return this.$store.getters["md_fire/gt_curriculumSelected"];
      },
      async set(value) {
        let l_payload = null;
        if (value && value.key) l_payload = value.key;

        await this.$localforage.setItem("fs_curriculum", l_payload);
        this.$store.commit("md_fire/mt_setCurriculum", l_payload);
      },
    },
  },
  methods: {
    m_isSearchValid() {
      // console.log(this.co_selectedSubject && typeof this.co_selectedSubject['education'] !=undefined );
      if (!this.gt_subjects) return false;
      if (this.co_selectedSubject && this.co_selectedSubject.education)
        return (
          (this.co_selectedSubject.education.length > 0 &&
            this.co_selectedSubject.education == "s" &&
            this.co_selectedGrade &&
            this.co_selectedCurriculum) ||
          this.co_selectedSubject.education != "s"
        );
      return false;
    },
  },
  async mounted() {
    this.$store.commit("md_fire/mt_setHomepageSubject", "");
    this.$store.commit("md_fire/mt_setHomepageGrade", "");
    this.$store.commit("md_fire/mt_setCurriculum", "");

    let temp = await fb_eventsRef.get();
    temp = temp.data();

    if (temp.video != null) {
      this.event.video = temp.video;
    }
    if (temp.image != null) {
      this.event.image = temp.image;
    }
    if (temp.description.trim().length != 0) {
      this.event.description = temp.description;
    }
    if (temp.title.trim().length != 0) {
      this.event.title = temp.title;
    }
    if (temp.teachersNumber.trim().length != 0) {
      this.event.teachersNumber = temp.teachersNumber;
    }
    if (temp.studentsNumber.trim().length != 0) {
      this.event.studentsNumber = temp.studentsNumber;
    }
    if (temp.familiesNumber.trim().length != 0) {
      this.event.familiesNumber = temp.familiesNumber;
    }

    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 1500);
  },
};
</script>

<style>
.multiselect--disabled {
  background: #82bf6f !important;
  border-radius: 5px;
  border: none;
}
/* .multiselect__option {
  background: #82bf6f !important;
}*/
.multiselect__tags {
  background: #82bf6f !important;
  border: none;
}
.multiselect__option--highlight {
  background: #82bf6f !important;
}
/* .multiselect__option--selected {
  background: #d2555b !important;
} */
.multiselect__single {
  background-color: #82bf6f !important;
  color: #ffffff;
  font-weight: 600;
}
.multiselect__select:before {
  border-color: #fff transparent transparent;
}
.multiselect__input {
  background-color: #82bf6f;
  color: #ffffff;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #82bf6f;
}
.multiselect__input::placeholder {
  color: #ffffff !important;
}
.multiselect__input::-webkit-input-placeholder {
  color: #ffffff !important;
}

.multiselect__input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff !important;
}

.multiselect__input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff !important;
}

.multiselect__input:-ms-input-placeholder {
  color: #ffffff !important;
}
.multiselect__placeholder {
  color: #ffffff;
}
</style>
<style scoped>
.round-search-button {
  padding: 9px 11px;
  border-radius: 50%;
}
.search-title {
  color: #ffffff;
}
.search-wrapper {
  background: rgba(255, 255, 255, 0.3);
  padding: 20px;
  border-radius: 15px;
}
@media only screen and (max-width: 991px) {
  .main-search-parent-button {
    text-align: center;
  }

  .search-wrapper {
    background: rgba(255, 255, 255, 0.2);
  }
}
.hero-banner {
  padding: 90px 0;
}

.page-container {
  padding-top: env(safe-area-inset-top);
}
</style>
